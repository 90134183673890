import React from 'react'
import { Button, Table2 } from 'src/ui'
import { useDisclosure, VStack } from '@chakra-ui/react'
import { useLocationDetailContext } from 'src/companies/locations/v2/location-details-context'
import { FormLabel } from 'src/ui/form-controls'
import InfiniteModal from 'src/companies/locations/v2/facility-management/infinite-modal'
import { getLocationDisplayName, LocationType } from 'src/companies/locations/v2/utils'

export default function AddLocationParent() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { parent, setParent } = useLocationDetailContext()

  function handleAddParentClicked(location: TenantLocation) {
    setParent(location)
  }

  return (
    <>
      <VStack spacing={4} alignItems="start" width="100%">
        <FormLabel>Parent Location</FormLabel>
        {parent ? (
          <Table2 justifyContent="start">
            <thead>
              <tr>
                <th>Name</th>
                <th>Remote ID</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{getLocationDisplayName(parent)}</td>
                <td>{parent.sourceId}</td>
                <td align="right">
                  <Button
                    variant="ghost"
                    color="red.500"
                    fontSize="sm"
                    onClick={() => setParent(null)}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table2>
        ) : (
          <Button colorScheme="primary" maxW={240} onClick={onOpen}>
            Add Parent Location
          </Button>
        )}
      </VStack>
      <InfiniteModal
        isOpen={isOpen}
        onClose={onClose}
        handleAddClicked={handleAddParentClicked}
        locationType={LocationType.location}
      />
    </>
  )
}
