import React, { useEffect, useMemo, useState } from 'react'
import { useCompanyContext } from 'src/companies/routes/company-context'
import Table2, { useTable } from 'src/ui/table2'
import { Box, Button, Center, HStack, Spinner, VStack } from '@chakra-ui/react'
import { getEnvironment, useDebounce } from 'src/utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Row } from 'src/ui'
import { hasPermission } from 'src/utils/permissions'
import { useGetAllLocations } from 'src/api/queries/microservices/tenants'
import { Link } from 'react-router-dom'
import SearchInput from 'src/ui/search-input'
import { getLocationDisplayName } from './utils'

function EditButton({ row }) {
  const { company } = useCompanyContext()
  return (
    <Row justifyContent="flex-end">
      <Button
        size="sm"
        color="primary.500"
        variant="ghost"
        as={Link}
        to={[null, 'companies', getEnvironment(), company.slug, 'locations', row.original.id].join(
          '/'
        )}
      >
        Edit
      </Button>
    </Row>
  )
}

export default function LocationsList() {
  const { company } = useCompanyContext()
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearch = useDebounce(searchValue, 500)
  const { data, fetchNextPage, hasNextPage, isFetching, error, isLoading } = useGetAllLocations({
    tenantId: company.tenant_id,
    search: debouncedSearch,
  })

  let columns = useMemo(
    () => [
      {
        id: 'name',
        accessor: (row) => getLocationDisplayName(row),
        Header: 'Name',
        disableSortBy: true,
      },
      {
        id: 'sourceId',
        accessor: 'sourceId',
        Header: 'Remote ID',
        disableSortBy: true,
      },
      {
        id: '_actions',
        accessor: '_actions',
        Header: '',
        disableSortBy: true,
        Cell: EditButton,
      },
    ],
    []
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data,
    tableOptions: {
      initialState: {
        sortBy: [],
      },
    },
    columns,
  })

  /**
   * This will load enough data to fill the page and make a scrollbar to appear. At that point,
   * react-infinite-scroll-component can handle loading additional data.
   */
  useEffect(() => {
    if (
      !isFetching &&
      !error &&
      hasNextPage &&
      document.getElementById('locations_scroll_container').scrollHeight <= window.innerHeight
    ) {
      fetchNextPage()
    }
  }, [isFetching, hasNextPage, fetchNextPage, error])

  return (
    <VStack align="start">
      <HStack width="100%" justifyContent="space-between">
        <SearchInput
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isLoading={isLoading}
          width="400px"
        />
        <Box flex={1} textAlign="right">
          <Button
            as={Link}
            size="sm"
            type="button"
            width="70px"
            colorScheme="primary"
            isDisabled={!hasPermission('company_locations_edit')}
            to={[null, 'companies', getEnvironment(), company.slug, 'locations', 'new'].join('/')}
          >
            Add
          </Button>
        </Box>
      </HStack>
      <Box
        id="locations_scroll_container"
        overflowY="auto"
        height="calc(100vh - 180px)"
        width="100%"
      >
        <Box bg="white" mb={4} pt={3} borderRadius={3} boxShadow="md">
          <InfiniteScroll
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={
              <Center h="50px">
                <Spinner speed="0.8s" thickness="3px" color="inherit" />
              </Center>
            }
            dataLength={data?.length ?? 0}
            scrollableTarget="locations_scroll_container"
          >
            <Table2 {...getTableProps} striped>
              <Table2.Header headerGroups={headerGroups} />
              <Table2.Body
                rows={rows}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
              />
            </Table2>
          </InfiniteScroll>
        </Box>
      </Box>
    </VStack>
  )
}
